// extracted by mini-css-extract-plugin
export var caseContactFormSection = "bH_k4";
export var caseStudyBackground__lineColor = "bH_kW";
export var caseStudyHead = "bH_k6";
export var caseStudyHead__imageWrapper = "bH_kT";
export var caseStudyProjectsSection = "bH_k5";
export var caseStudyQuote__bgRing = "bH_k1";
export var caseStudyVideo__ring = "bH_k8";
export var caseStudy__bgDark = "bH_kS";
export var caseStudy__bgDarkReverse = "bH_ml";
export var caseStudy__bgLight = "bH_kR";